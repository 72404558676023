import React, { useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiChevronRight } from 'react-icons/fi';
import { Analytics } from "@vercel/analytics/react";
import profilePhoto from './pfp.jpeg'; // Import your photo
import Story from './components/Story';
import Thoughts from './components/Thoughts';
import CheskyFounderMode from './components/CheskyFounderMode';
import StartingHere from './components/StartingHere'; // Add this import
import ErrorBoundary from './ErrorBoundary';

function App() {
  const [hoveredLink, setHoveredLink] = useState(null);

  const links = [
    { text: 'See my story', href: '/story' },
    { text: 'Follow my thoughts', href: '/thoughts' },
    { text: 'Send me a message', href: 'https://www.linkedin.com/in/chase-richter/' },
  ];

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={
          <div className="flex flex-col items-center justify-center min-h-screen bg-background text-primary p-8 overflow-hidden">
            <div className="flex flex-col items-center max-w-md w-full"> {/* Added container */}
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="mb-10"
                whileTap={{ scale: 0.9 }}
              >
                <motion.img
                  src={profilePhoto}
                  alt="Chase Richter"
                  className="w-48 h-48 object-cover shadow-lg"
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 300 }}
                />
              </motion.div>
              <motion.h1
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-5xl font-bold mb-10 text-center text-primary"
              >
                Chase Richter
              </motion.h1>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="space-y-6 flex flex-col items-center w-full"
              >
                {links.map((link, index) => (
                  <motion.div
                    key={link.text}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.6 + index * 0.1, duration: 0.5 }}
                  >
                    <Link to={link.href}>
                      <motion.span
                        className="group flex items-center text-2xl text-primary hover:text-[#207fde] transition-colors duration-200"
                        onMouseEnter={() => setHoveredLink(link.text)}
                        onMouseLeave={() => setHoveredLink(null)}
                        whileHover={{ x: 10 }}
                      >
                        {link.text}
                        <motion.span
                          initial={{ opacity: 0, x: -10 }}
                          animate={{
                            opacity: hoveredLink === link.text ? 1 : 0,
                            x: hoveredLink === link.text ? 0 : -10,
                          }}
                          transition={{ duration: 0.2 }}
                          className="ml-3"
                        >
                          <FiChevronRight className="w-6 h-6" />
                        </motion.span>
                      </motion.span>
                    </Link>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
        } />
        <Route path="/story" element={<Story />} />
        <Route path="/thoughts" element={<Thoughts />} />
        <Route path="/thoughts/chesky-founder-mode" element={<CheskyFounderMode />} />
        <Route path="/thoughts/starting-here" element={<StartingHere />} /> {/* Add this new route */}
      </Routes>
      <Analytics />
    </ErrorBoundary>
  );
}

export default App;