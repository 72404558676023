import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function StartingHere() {
  return (
    <div className="page-content bg-background text-primary">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-3xl mx-auto"
      >
        <h1 className="text-4xl font-bold mb-6">Starting Here..</h1>
        
        <div className="space-y-6 text-lg">
          <p>8.15.24</p>
          <p>Hey there.</p>

          <p>My name is Chase Richter.</p>

          <p>I am an entrepreneur at heart. I started my first business in 7th grade. It was a really successful Minecraft network.</p>

          <p>I'm now an early career product manager with a burning passion to build products.</p>

          <p>I currently work at Plai Labs. We're building <a href="https://getsalt.ai/" className="text-primary hover:underline">getsalt.ai</a>.</p>

          <p>I've been leading the e2e product development efforts.</p>

          <p>I drive the product strategy from the org's vision.</p>

          <p>I do market research to better understand our positioning.</p>

          <p>I author product requirements and manage the roadmap.</p>

          <p>I coordinate across design, engineering, and stakeholders to deliver on time.</p>

          <p>I solve ambiguous problems and create consensus among people.</p>

          <p>When it doesn't come to work.. I'm doing one of the following things :)</p>

          <ul className="list-disc pl-6 space-y-2">
            <li>Surfing → One of the best feelings in the world.</li>
            <li>Running → Running on the hudson is a great post work activity.</li>
            <li>Pickleball → Sunday pickleball with the crew!</li>
            <li>Eating → I can't get the reservation.</li>
            <li>Hiking → For when I go upstate.</li>
            <li>Billiards → My new favorite weeknight activity.</li>
          </ul>
        </div>
        
        <Link to="/thoughts" className="text-primary hover:underline mt-8 inline-block">
          Back to Thoughts
        </Link>
      </motion.div>
    </div>
  );
}

export default StartingHere;