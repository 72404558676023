import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function CheskyFounderMode() {
  return (
    <div className="page-content bg-background text-primary">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-3xl mx-auto"
      >
        <h1 className="text-4xl font-bold mb-6">Chesky's Founder Mode</h1>
        
        <div className="space-y-6 text-lg">
          <p>8.26.24</p>

          <p>
            Paul Graham's recent article on Founder Mode has sparked an interesting conversation. The concept, brought to the attention of many by Brian Chesky, suggests that the conventional mode of delegating work in a growing organization is more harmful then helpful.
          </p>

          <p>
            Brian Chesky was at a YC event speaking, and recounted the time where he was told to "hire good people and give them room to do their jobs". It led to a disaster. The sentiment resonated with many of the founders in the room, indicating a real issue with traditional management of high-growth orgs.
          </p>

          <p>
            Lets look at Steve Jobs. Steve Jobs provides a compelling example of founder mode in action. His annual retreats with 100 important people at Apple – not necessarily the highest ranking – demonstrates the characteristics of this leadership style.
          </p>

          <p>
            Founder mode can't be found in business books. Rather, it's becoming a silent norm in the Valley's most successful companies. You can read and hear about it all over Twitter today. But as this mode of thinking and operating becomes more popular, we will tend to see it being misused. As Graham notes, it may lead to an excuse to micromanage, or inability to delegate when necessary. These misapplications can lead to chaotic environments where the benefits of structured management are lost.
          </p>

          <p>
            You can read the article <a href="https://paulgraham.com/foundermode.html" className="text-primary hover:underline">here</a>.
          </p>
        </div>
        
        <Link to="/thoughts" className="text-primary hover:underline mt-8 inline-block">
          Back to Thoughts
        </Link>
      </motion.div>
    </div>
  );
}

export default CheskyFounderMode;