import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Sample article data
const articles = [
  { id: 1, title: "Chesky's Founder Mode", slug: "chesky-founder-mode" },
  { id: 2, title: "Starting here..", slug: "starting-here" },
];

function Thoughts() {
  return (
    <div className="page-content bg-background text-primary">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold mb-4">Thinking Out Loud</h2>
        <p className="text-primary max-w-2xl mx-auto">
          Some of my thoughts on product.
        </p>
      </div>
      
      <div className="space-y-3">
        {articles.map((article) => (
          <motion.div
            key={article.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Link to={`/thoughts/${article.slug}`}>
              <motion.div
                className="block p-3 bg-card rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out relative h-20"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <h2 className="text-xs font-semibold text-primary absolute top-1/2 left-3 transform -translate-y-1/2 origin-left scale-[0.6]">
                  {article.title}
                </h2>
              </motion.div>
            </Link>
          </motion.div>
        ))}
      </div>
      <Link to="/" className="text-primary hover:underline mt-8 inline-block">Back to Home</Link>
    </div>
  );
}

export default Thoughts;