import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Sample resume data
const resumeData = [
  {
    title: "Product Manager",
    company: "Salt AI",
    description: "Leading the product development of a cutting-edge node based AI development platform."
  },
  {
    title: "Associate Product Manager",
    company: "PlaiDay",
    description: "Served as the founding product manager for the PlaiDay mobile app, driving user acquisition to 60,000 registered users."
  },
  {
    title: "Venture Analyst",
    company: "Starburst Aerospace",
    description: "Worked with the Aerospace A&D group to procure high-potential companies for their startup incubator program."
  },
  {
    title: "Entrepreneurship and Data Science",
    company: "NYU Gallatin",
    description: "Learned about Startup Fundamentals, Statistics, UX Design, Information Design, and much more. "
  }
];

function Story() {
  const [activeIndex, setActiveIndex] = useState(0);
  const timelineRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveIndex(Number(entry.target.dataset.index));
          }
        });
      },
      { threshold: 0.5 }
    );

    timelineRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      timelineRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className="page-content bg-background text-primary">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold mb-4">Walk down memory lane..</h2>
        <p className="text-primary max-w-2xl mx-auto">
          Hey. I'm Chase. I currently work at Salt AI. I'm a high agency product manager and have a burning passion to create products. <span className="font-grace text-grace tracking-grace">I like to get sh*t done.</span> I have a background in Entrepreneurship and Data Science from NYU.
        </p>
      </div>
      
      <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:h-full before:w-0.5 before:-translate-x-px before:bg-gradient-to-b before:from-transparent before:via-[#e3decd] before:to-transparent">
        {resumeData.map((item, index) => (
          <motion.div
            key={index}
            ref={(el) => {
              if (el) timelineRefs.current[index] = el;
            }}
            data-index={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className={`relative flex items-start ${activeIndex === index ? 'opacity-100' : 'opacity-50'}`}
          >
            <div className="absolute left-0 mt-1 h-10 w-10 rounded-full bg-[#e3decd] flex items-center justify-center">
              <span className="text-primary font-bold">{item.year}</span>
            </div>
            <div className="ml-16 w-full bg-card p-4 rounded-lg shadow-sm">
              <h4 className="text-xl font-semibold mb-2 text-primary">{item.title}</h4>
              <p className="text-sm text-primary mb-2">{item.company}</p>
              <p className="mb-4 text-primary">{item.description}</p>
            </div>
          </motion.div>
        ))}
      </div>

      <Link to="/" className="text-primary hover:underline mt-8 inline-block">Back to Home</Link>
    </div>
  );
}

export default Story;